import React, { Component } from 'react';
import Profile from './Profile';
import "./Home.css";
import { withRouter } from "react-router-dom";

class About extends Component {  

  componentDidMount() {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://platform.linkedin.com/badges/js/profile.js";
    document.body.appendChild(script);
  }
render() {

    return (
      <React.Fragment>
      <div className="Home">
      <Profile />
      </div>
        <h2>Contact Details</h2>
        <p>
          <span>ugur@pinarfamily.com</span>
        </p>
      <div class="LI-profile-badge"  data-version="v1" data-size="medium" data-locale="en_US" data-type="horizontal" data-theme="light" data-vanity="nevzatpinar"><a class="LI-simple-link" href='https://www.linkedin.com/in/nevzatpinar?trk=profile-badge'>Nevzat Ugur Pinar</a></div>
  
   </React.Fragment>
    );
}
  
}

export default withRouter(About);