import React, { useState, useEffect } from "react";
import { ListGroup, ListGroupItem, Navbar} from "react-bootstrap";
import "./Home.css";
import { API } from "aws-amplify";
import { LinkContainer } from "react-router-bootstrap";
import PageHeader from 'react-bootstrap/lib/PageHeader'
import { Link } from "react-router-dom";


export default function Home(props) {
  const [notes, setNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function onLoad() {
      if (!props.isAuthenticated) {
        return;
      }
  
      try {
        const notes = await loadNotes();
        setNotes(notes);
      } catch (e) {
        alert(e);
      }
  
      setIsLoading(false);
    }
  
    onLoad();
  }, [props.isAuthenticated]);
  
  function loadNotes() {
    return API.get("notes", "/notes");
  }

  function renderNotesList(notes) {
    return [{}].concat(notes).map((note, i) =>
      i !== 0 ? (
        <LinkContainer key={note.noteId} to={`/notes/${note.noteId}`}>
          <ListGroupItem header={note.content.trim().split("\n")[0]}>
            {"Created: " + new Date(note.createdAt).toLocaleString()}
          </ListGroupItem>
        </LinkContainer>
      ) : (
        <LinkContainer key="new" to="/notes/new">
          <ListGroupItem>
            <h4>
              <b>{"\uFF0B"}</b> Create a new note
            </h4>
          </ListGroupItem>
        </LinkContainer>
      )
    );
  }

  function renderLander() {
    return (
      
      <React.Fragment>
      {/*generated code*/}
      
    
      <div className="row banner">
      <div class="banner-text">

        <h1>Always up to date!</h1>
        <h3>I'm a passionate software engineer who likes to build <span>applications</span> and <span>custom tools</span>, and <span> improve my development
          skills</span> every day.</h3> 
        <p>
          <Navbar.Brand>
            <Link to="/about">Click here to learn more about me.</Link>
          </Navbar.Brand>
       </p>
       <p>
       <h4>I am currently working on developing <span>serverless applications</span> and enhancing my skills with <span>serverless patterns</span> and <span>architectures</span>.</h4>
       </p>
      </div>
      </div>
      
    
      </React.Fragment>
    );
  }

  function renderNotes() {
    return (
      <div className="notes">
        <PageHeader>Your Notes</PageHeader>
        <ListGroup>
          {!isLoading && renderNotesList(notes)}
        </ListGroup>
      </div>
    );
  }

  return (
    <div className="Home">
      {props.isAuthenticated ? renderNotes() : renderLander()}
    </div>
  );
}