import React, { Component } from 'react';
import { Button, Jumbotron } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Home";

export default class Projects extends Component {
  render() {
    return (
      <React.Fragment>
        <Jumbotron>
        <div className="Home">
         <h2>Serverless note and file storage app.</h2>
        <p>
        Note taking app is a serverless and single page application that is implemented with AWS platform and React.
        The application is implemented with the following technologies:
        <ul>
          <li>Cognito for user authentication and securing the APIs.</li>
          <li>API Gateway & Lambda for serverless API.</li>
          <li>DynamoDB for backend.</li>
          <li>S3 storage for file uploads.</li>
          <li>Route 53 for domain management.</li>
          <li>Certificate Manager for SSL.</li>
          <li>AWS Amplify for hosting the app and automating frontend build and deployment.</li>
          <li>Codepipeline for automating backend build and deployment.</li>
          <li>React.js, React Router and Bootstrap are used to build the frontend.</li>
        </ul>
        <Button bsStyle="info">
            <Link to="/signup">note and file storage app</Link>
        </Button>
        </p>
        
        </div>
        </Jumbotron>
        <Jumbotron>
        <div className="Home">
         <h2>Serverless player dashboard app.</h2>
        <p>
        Player dashboard is a simple serverless CRUD application that is implemented with AWS platform and AngularJS.
        The application is implemented with the following technologies:
        <ul>
          <li>Angular is used to build the frontend.</li>
          <li>S3 bucket is used for static website hosting feature.</li>
          <li>API Gateway & Lambda for serverless API.</li>
          <li>DynamoDB for backend.</li>
          <li>AWS Codepipeline for automating frontend and backend build and deployment.</li>
        </ul>
        <Button bsStyle="info">
            <a href="http://thatsthecode.com.s3-website-us-east-1.amazonaws.com">player dashboard app</a>
        </Button>
        </p>
  
        </div>
        </Jumbotron>
      </React.Fragment>
    );
  }
}