import React, { useState, useEffect } from "react";
import "./App.css";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";
import { Link, withRouter } from "react-router-dom";
import { Nav, Navbar, NavItem } from "react-bootstrap";
import { Auth } from "aws-amplify";
import Footer from './containers/Footer';

function App(props) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [isAuthenticated, userHasAuthenticated] = useState(false);
  useEffect(() => {
    onLoad();
  }, []);
  
  async function onLoad() {
    try {
      await Auth.currentSession();
      userHasAuthenticated(true);
    }
    catch(e) {
      if (e !== 'No current user') {
        alert(e);
      }
    }
  
    setIsAuthenticating(false);
  }

  async function handleLogout() {
    await Auth.signOut();

    userHasAuthenticated(false);
    props.history.push("/login");
  }

  return (
    !isAuthenticating &&
    <div className="App container">
      <Navbar fluid collapseOnSelect>
        <Navbar.Header>
        <div class="banner-text">
          <Navbar.Brand>
            <Link to="/">Nevzat Ugur Pinar</Link>
          </Navbar.Brand>
          </div>
          <Navbar.Toggle/>
        </Navbar.Header>
        <Navbar.Collapse>
          <Nav pullRight>
          {isAuthenticated
          ? <NavItem onClick={handleLogout}>Logout</NavItem>
          : <>
          <Nav pullRight>
          <LinkContainer to="/home">
          <NavItem>Home</NavItem>
         </LinkContainer>
          <LinkContainer to="/about">
          <NavItem>About</NavItem>
         </LinkContainer>
         <LinkContainer to="/projects">
          <NavItem>Projects</NavItem>
         </LinkContainer>
         <LinkContainer to="/serverless">
          <NavItem>Serverless</NavItem>
         </LinkContainer>
         </Nav>
          </>
          }
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <Routes appProps={{ isAuthenticated, userHasAuthenticated }} />
      <Footer />
    </div>
  );
}

export default withRouter(App);