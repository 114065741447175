import React, { Component } from 'react';
import { Jumbotron, Table} from "react-bootstrap";
import "./Home";

export default class Serverless extends Component {
  render() {
    return (
      <React.Fragment>
        <Jumbotron>
        <div className="Home">
         <h2>Why Serverless?</h2>
        <p>
        I decided to take my skills to the next level with serverless computing after seeing benefits of it such as low cost, low maintenace and speed of development. 
        This website itself is created with serverless stack and hosted on AWS platform. Let's look at the technologies used and the cost.
        Following technologies are used to create this website:
        <ul>
          <li>I chose React.js for the UI since I had experience with AngularJS and wanted to learn React.</li>
          <li>React Router to make the website single page application</li>
          <li>React Bootstrap for CSS styling</li>
          <li>API Gateway & Lambda for serverless API.</li>
          <li>DynamoDB for backend.</li>
          <li>S3 for file uploads and storage.</li>
          <li>AWS Amplify for hosting the website and automating the frontend build and deployment.</li>
          <li>Cognito for user authentication and securing the APIs.</li>
          <li>Route 53 for domain management.</li>
          <li>Certificate Manager for SSL.</li>
          <li>Codepipeline for automating backend build and deployment.</li>
        </ul>
        </p>
        
        <p>
        The following table shows how much it costs me to run this website on AWS per month. It is very inexpensive because I am only paying per requests, and if there
        are no requests, there is no charge except Route53 hosted zone.
        </p>
        <Table responsive bordered condensed hover>
            <thead>
                <tr>
                <th>Service</th>
                <th>Rate</th>
                <th>Cost</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                <td>Route53</td>
                <td>$0.50 per hosted zone + $0.40 per 1 million queries</td>
                <td>$0.50</td>
                </tr>
                <tr>
                <td>Lambda</td>
                <td>Free 1M requests</td>
                <td>$0.00</td>
                </tr>
                <tr>
                <td>API Gateway</td>
                <td>$3.50/million requests - first 333 million requests/month</td>
                <td>$0.00</td>
                </tr>
                <tr>
                <td>DynamoDB</td>
                <td>$0.25 per million read request units and $1.25 per million write request units</td>
                <td>$0.00</td>
                </tr>
                <tr>
                <td>S3</td>
                <td>$0.023 per GB storage used, $0.005 per 1,000 PUT, COPY, POST, or LIST requests, $0.004 per 10,000 GET and all other requests</td>
                <td>$0.01</td>
                </tr>
                <tr>
                <td>Certificate Manager</td>
                <td>Free</td>
                <td>$0.00</td>
                </tr>
                <tr>
                <td>Cognito</td>
                <td>Free</td>
                <td>$0.00</td>
                </tr>
                <tr>
                <td>Amplify</td>
                <td>1000 build minutes per month free. $0.01 per build minute afterwards.</td>
                <td>$0.00</td>
                </tr>
                <tr>
                <td>CloudWatch</td>
                <td>First 5GB per month of logs storage is free.</td>
                <td>$0.00</td>
                </tr>
                <tr>
                <td>CodePipeline</td>
                <td>$0.00 for first active pipeline. $1.00 per active pipeline per month.</td>
                <td>$0.00</td>
                </tr>
                <tr>
                <td>US Sales Tax</td>
                <td></td>
                <td>$0.04</td>
                </tr>
                <tr>
                <td>Total</td>
                <td></td>
                <td>$0.55</td>
                </tr>
            </tbody>
        </Table>
        <p>
        As you can see from the above break down, my total cost is $0.54 for running my serverless application on AWS. This is a lot cheaper compare to
        if I were hosting it on a web hosting provider like GoDaddy, Bluehost or SiteGround.
        </p>
        </div>
        </Jumbotron>
      
      </React.Fragment>
    );
  }
}