import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import NotFound from "./containers/NotFound";
import Login from "./containers/Login";
import AppliedRoute from "./components/AppliedRoute";
import Signup from "./containers/Signup";
import NewNote from "./containers/NewNote";
import Notes from "./containers/Notes";
import AuthenticatedRoute from "./components/AuthenticatedRoute";
import UnauthenticatedRoute from "./components/UnauthenticatedRoute";
import About from "./containers/About";
import Projects from "./containers/Projects";
import Serverless from "./containers/Serverless";
import ScrollToTop from "./components/ScrollToTop"

export default function Routes({ appProps }) {
    return (
      <ScrollToTop>
      <Switch>
        <AppliedRoute path="/" exact component={Home} appProps={appProps} />
        <AppliedRoute path="/index.html" exact component={Home} appProps={appProps} />
        <UnauthenticatedRoute path="/home" exact component={Home} appProps={appProps} />
        <UnauthenticatedRoute path="/login" exact component={Login} appProps={appProps} />
        <UnauthenticatedRoute path="/signup" exact component={Signup} appProps={appProps} />
        <AuthenticatedRoute path="/notes/new" exact component={NewNote} appProps={appProps} />
        <AuthenticatedRoute path="/notes/:id" exact component={Notes} appProps={appProps} />
        <UnauthenticatedRoute path="/about" exact component={About} appProps={appProps} />
        <UnauthenticatedRoute path="/projects" exact component={Projects} appProps={appProps} />
        <UnauthenticatedRoute path="/serverless" exact component={Serverless} appProps={appProps} />
        { /* Finally, catch all unmatched routes */ }
        <Route component={NotFound} />
      </Switch>
      </ScrollToTop>
    );
  }