  const dev = {
    s3: {
      REGION: "us-east-1",
      BUCKET: "notes-app-api-serverless-dev-attachmentsbucket-rojk06ylxl25"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://o29f3io6x3.execute-api.us-east-1.amazonaws.com/dev"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_1Euhcy74q",
      APP_CLIENT_ID: "4gkds9t9dse1sgem124ip0mbsn",
      IDENTITY_POOL_ID: "us-east-1:adf0db9e-5deb-413a-90ac-5b194aa31770"
    }
  };
  
  const prod = {
    s3: {
      REGION: "us-east-1",
      BUCKET: "notes-app-api-serverless-prod-attachmentsbucket-1b97qrvyk8vry"
    },
    apiGateway: {
      REGION: "us-east-1",
      URL: "https://y3e3a86byl.execute-api.us-east-1.amazonaws.com/prod"
    },
    cognito: {
      REGION: "us-east-1",
      USER_POOL_ID: "us-east-1_ZScD8Rmjo",
      APP_CLIENT_ID: "3un5eruai7ttgkosictket5al5",
      IDENTITY_POOL_ID: "us-east-1:1ac137df-bec9-4362-bdd3-c026d2457b18"
    }
  };
  
  // Default to dev if not set
  const config = process.env.REACT_APP_STAGE === 'prod'
    ? prod
    : dev;
  
  export default {
    // Add common config values here
    //this is for test 2
    MAX_ATTACHMENT_SIZE: 5000000,
    ...config
  };